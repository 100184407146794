@use "sass:color";

@import "variables";
@import "constants";
@import "media";

@import "./icons";
@import "./components";

@import "./Home.scss";
@import "./Work.scss";
@import "./Contact.scss";
@import "./AboutUs.scss";
@import "./SingleProject.scss";

:root {
  font-size: 18px;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

html {
  -webkit-text-size-adjust: none;

  body {
    margin: 0;
    padding: 0;
    background: linear-gradient(111.17deg, $clr-bg-lighter 0.23%, $clr-bg-darker 100%);
    font-family: "Brandon";
    color: $clr-accent;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.primary-input {
  background: #030F26;
  border: 0;
  border-radius: 8px;
  padding: 0 18px;
  height: 48px;
  width: 100%;
  font-family: $primary-font;
  font-size: 16px;
  padding: 12px 18px;
  color: white;

  @include over-tablet {
    font-size: 18px;
    padding: 18px;
    height: 60px;
  }

  &:focus {
    outline: 0;
  }

  &--textarea {
    height: 225px;
    resize: none;

    @include over-tablet {
      height: 188px;
    }

  }
}

.primary-btn {
  cursor: pointer;
  padding: 12px 30px;
  border: none;
  border-radius: radius(1.6);
  background: $clr-primary;
  color: $clr-accent;
  font-size: 18px;
  width: fit-content;
  transition: all ease 0.4s;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  outline: none;
  text-decoration: unset;

  @include over-tablet {
    padding: 18px 36px;
  }

  &:hover {
    background: color.scale($clr-primary, $lightness: -10%);
    box-shadow: 0px 15px 20px rgba($clr-primary, 0.4);
    transform: translateY(-4px);
  }

  &--inverted {
    background: transparent;
    border: 1px solid $clr-primary;

    &:hover {
      background: transparent;
      box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
      transform: translateY(-4px);
    }
  }
}

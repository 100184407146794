footer {
  display: flex;
  background: #030f26;
  margin-top: 84px;

  .footer {
    color: $clr-accent;
    display: flex;
    flex-wrap: wrap;
    padding: 60px 30px;
    margin: auto;
    width: 100%;
    max-width: 1000px;

    @include over-tablet-medium {
      justify-content: space-between;
      flex-wrap: nowrap;
      padding-top: 100px;
    }

    section {
      display: flex;
      flex-direction: column;
      row-gap: 18px;
      height: fit-content;
      margin-bottom: 60px;

      .footer-logo-holder {
        display: flex;
        flex-direction: row;
      }

      .footer-logo {
        height: 25px;
        width: 25px;
      }

      .footer-logo-name {
        font-size: 14px;
        letter-spacing: 0.2em;
        margin-left: 1.5em;
      }

      .footer-text-address {
        line-height: 32px;
      }

      .footer-text {
        font-size: 18px;
        line-height: 25px;
      }

      a {
        color: $clr-accent;
        text-decoration: none;
      }

      &:first-child {
        width: 100%;

        @include over-tablet-medium {
          width: unset;
        }

        p {
          max-width: 252px;
        }
      }

      &:nth-child(2),
      &:nth-child(3) {
        width: 50%;

        @include over-tablet-medium {
          width: unset;
        }
      }

      &:nth-child(3) {
        p {
          text-align: end;

          @include over-mobile {
            text-align: unset;
          }

          @include over-tablet-medium {
            text-align: end;
          }
        }
      }
    }

    &-links-mobile {
      @include over-tablet-medium {
        display: none;
      }
    }

    &-links-desktop {
      display: none;

      @include over-tablet-medium {
        display: flex;
        width: 240px;
        margin-top: 43px;
      }
    }
  }
}

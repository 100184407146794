.testimonial {
  padding: 120px 24px;
  color: $clr-accent;
  text-align: center;
  overflow: clip;

  &-stars {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }

  &-message {
    position: relative;
    font-size: 16px;
    font-weight: 500;
    max-width: 600px;
    margin: 0 auto;
    line-height: 24px;
    margin-bottom: 36px;

    @include over-tablet-medium {
      font-size: 24px;
      line-height: 36px;
    }

    &::before,
    &::after {
      position: absolute;
      font-size: 288px;
      color: rgba(255, 255, 255, 0.1);
    }

    &::before {
      content: "\201C";
      top: -24px;
      left: -48px;
    }

    &::after {
      content: "\201D";
      bottom: -120px;
      right: -48px;
    }
  }

  .more-reviews {
    color: #C1C1C1;
    font-size: 14px;
  }
}

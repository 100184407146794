.work {
  color: $clr-accent;
  padding: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  align-items: center;

  &-tagline {
    max-width: 300px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 96px;

    @include over-tablet {
      font-size: 24px;
      line-height: 48px;
      max-width: 555px;
    }
  }

  .work-list {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 95%;
    row-gap: 60px;
    column-gap: 24px;

    @include over-tablet {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin-top: 120px;
      row-gap: 96px;
    }

    &-empty {
      display: none;

      @include over-tablet {
        display: block;
      }
    }

    &-image {
      display: none;

      @include over-tablet {
        position: absolute;
        background: rgba($color: $clr-accent, $alpha: 0.05);
        padding: 16px;
        border-radius: 10px;
        width: calc(50% - 12px);
        top: 0;
        right: 0;
        display: block;
      }

      img {
        width: 100%;
        background-size: cover;
        background-position: center center;
        border-radius: radius(1);
        transition: background-image 0.3s ease;
      }
    }
  }
}

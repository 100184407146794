.home {
  display: grid;
  grid-template-columns: auto;
  height: calc(100vh - $nav-height-mobile);

  @include over-mobile {
    // grid-template-columns: 100px auto 100px;
    height: calc(100vh - $nav-height-desktop);
  }

  &-middle {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 24px;

    @include over-mobile-large {
      padding: 48px;
    }

    &-text {
      display: grid;
      column-gap: 78px;
      row-gap: 18px;
      font-family: $primary-font;
      max-width: 600px;
      margin-bottom: 30px;

      @include over-tablet-large {
        max-width: 1000px;
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 60px;
      }
    }

    &-buttons {
      max-width: 600px;
      width: 100%;

      .home-button {
        margin-right: 18px;
        width: 178px;
        text-align: center;

        &:last-child {
          margin-right: 0;
        }
      }

      .shadow {
        box-shadow: 8px 4px 36px rgba(9, 180, 119, 0.25);
      }

      @include over-tablet-large {
        display: grid;
        grid-template-columns: repeat(2, auto);
        column-gap: 78px;
        width: fit-content;
        max-width: 1000px;

        .home-button {
          margin-right: unset;
        }
      }
    }
  }

  &-tagline {
    color: $rgba-light-green;
    font-size: 32px;
    line-height: 32px;
    font-weight: bold;

    @include over-tablet {
      font-size: 48px;
      line-height: 48px;
    }

    @include over-tablet-large {
      text-align: right;
    }
  }

  &-info {
    width: 100%;
    font-size: 24px;
    line-height: 36px;
    color: $clr-accent;
    font-weight: 420;

    span {
      color: #4DE5AF;
    }
  }
}

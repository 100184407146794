.user-pill {
  display: flex;
  column-gap: 8px;

  img {
    height: 24px;
    width: 24px;
    border-radius: radius();
    object-fit: cover;
  }

  p {
    line-height: 24px;
    font-size: 16px;
    color: #C4C4C4;
  }
}

.card-stack {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
  width: 600px;

  &-wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    &-arrow {
      position: absolute;
      right: -24px;
      top: 50%;

      &-left {
        position: absolute;
        right: 98%;
        top: 50%;
        
        svg {
          transform: rotateY(180deg);
        }
      }
    }

    &-card {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      transform-origin: 50% 50% 0px;
      list-style: none;
      box-shadow: 10px 4px 30px rgba(0, 0, 0, 0.25);
    }
    p {
      font-size: 24px;
      line-height: 36px;
      font-weight: 500;
      max-width: 240px;
      margin-bottom: 36px;

      @include over-tablet-medium {
        font-size: 36px;
        line-height: 60px;
      }
    }
  }
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: linear-gradient(111.17deg, #041534 0.23%, #030f26 100%);
  z-index: 10;

  &-logo {
    height: 25px;
    width: 25px;
  }

  &-body {
    @include flex-center;
    flex: 1;

    div {
      display: flex;
      align-items: center;
      flex-direction: column;
      row-gap: 60px;

      a {
        font-size: 24px;
        line-height: 2;
        color: #FFFFFF;
        text-decoration: none;

        @include over-mobile {
          font-size: 36px;
        }
      }
    }
  }

  &-footer {
    @include flex-center;
    height: 48px;
    border-top: 1px solid rgba(255, 255, 255, 0.05);

    @include over-mobile {
      height: 84px;
    }
  }
}

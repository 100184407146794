.project-page {
  overflow-x: hidden;
  overflow-y: clip;

  .line-height-correction {
    line-height: 55px;
  }

  .correction {
    width: 100%;
  }

  .layover {
    position: absolute;
    top: -10px;
    left: 0;
    background-color: rgb(0, 0, 0, 0.4);
    height: 200vh;
    width: 100%;
    z-index: 9;
  }

  .project-side {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    height: 100%;
    width: 100%;
    z-index: 10;

    &-body {
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;
      row-gap: 48px;
      padding: 42px;
      max-width: 400px;
      width: 100%;
      background: #041533;
      overflow-y: auto;

      &::before {
        content: "";
        position: absolute;
        left: -300px;
        height: 100%;
        width: 300px;
        background-color: #041533;
      }

      section {
        display: flex;
        flex-direction: column;
        row-gap: 8px;

        > h4 {
          font-weight: bold;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0.12em;
          color: #e7e7e7;
          text-transform: uppercase;
        }

        > p {
          font-size: 16px;
          line-height: 24px;
          color: #c4c4c4;
        }

        .project-side-person {
          margin-bottom: 4px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    button {
      @include flex-center;
      width: 56px;
      height: 56px;
      min-width: 56px;
      min-height: 56px;
      background: rgba(0, 0, 0, 0.4);
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
      backdrop-filter: blur(50px);
      border-radius: radius();
      outline: 0;
      border: 0;
      padding: 0;
      margin: 12px 16px;
      cursor: pointer;
    }
  }

  .project-details {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 24px;
    row-gap: 48px;
    padding: 24px;
    color: $clr-accent;

    @include over-tablet-medium {
      grid-template-columns: repeat(2, 1fr);
      .project-information {
        margin-left: 30px;
      }
    }

    .project-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;

      section {
        display: flex;
        flex-direction: column;
        row-gap: 24px;
      }

      &-part {
        padding-left: 4.5rem;
      }

      &-back {
        height: 28px;
        width: 28px;
        transform: rotate(180deg);
        margin-right: 6px;
        cursor: pointer;
      }

      &-icon {
        margin-right: 12px;
      }

      &-name {
        display: flex;
        align-items: center;
        font-size: 3rem;
        font-weight: 700;
      }

      &-description {
        font-size: 1.35rem;
        line-height: 1.5;
        color: #dedede;

        @include over-tablet-medium {
          font-size: 28px;
        }
      }

      &-labels {
        display: flex;
        flex-wrap: wrap;
        row-gap: 12px;
      }

      &-people {
        display: none;
        grid-template-columns: repeat(3, 1fr);
        justify-content: space-between;
        padding: 30px 3rem 30px 0;
        max-width: 800px;

        @include over-tablet-medium {
          display: flex;
        }
      }

      &-section {
        h4 {
          font-family: $primary-font;
          font-weight: bold;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0.25em;
          color: #e7e7e7;
          text-transform: uppercase;
          margin-bottom: 8px;
        }
      }

      &-person {
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &-button {
        display: none;
        padding-left: 60px;
        margin-left: -48px;
        width: fit-content;

        @include over-tablet-medium {
          display: flex;
        }
      }
    }

    &-pic {
      width: 100%;
      border-radius: 5px;
      border: 36px solid rgba(255, 255, 255, 0.05);
      height: fit-content;

      @include over-desktop {
        width: 95%;
      }
    }
  }

  .project-more {
    margin-left: -30px;
    padding-left: 60px;

    @include over-tablet-medium {
      display: none;
    }
  }

  .project-sections {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 42px 0;
    color: $clr-accent;
    text-align: center;

    h5 {
      font-family: $primary-font;
      font-weight: 900;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: rgba(#ffffff, 0.5);
      margin: 0;
      margin-bottom: 6px;
    }

    > p {
      font-size: 24px;
      line-height: 36px;
      font-weight: 500;
      max-width: 240px;
      margin-bottom: 36px;

      @include over-tablet-medium {
        font-size: 36px;
        line-height: 60px;
        max-width: unset;
      }
    }

    &-stack {
      width: calc(100% - 64px);
      max-width: 1600px;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        pointer-events: none;
        border-radius: radius(1.6);
      }
    }

    &-colors {
      width: 100%;
    }

    &-slider {
      .swiper-slide {
        margin: 0 9px;
        border-radius: radius();

        @include over-tablet-medium {
          margin: 0 12px;
        }

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          pointer-events: none;
          border-radius: radius(1.6);
        }
      }
    }
  }
  .project-video {
    &-title {
      font-family: $primary-font;
      font-weight: 900;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: rgba(#ffffff, 0.5);
      margin: 0;
      margin-bottom: 10px;
      margin-top: 50px;
    }

    &-text {
      font-size: 24px;
      line-height: 36px;
      font-weight: 500;
      margin-bottom: 36px;
      text-align: center;
      width: 100%;

      @include over-tablet-medium {
        font-size: 28px;
      }
    }

    &-holder {
      width: 100%;
      display: flex;
      justify-content: center;

      @include tablet-medium {
        padding: 0px 10px;
      }
    }
    &-embed {
      border-radius: 8px;
      @include tablet-medium {
        height: 76vw;
        width: 100%;
      }

      @include over-tablet-medium {
        width: 90%;
        height: 700px;
      }

      @include over-tablet-large {
        width: 1000px;
        height: 750px;
      }
    }
  }
  // .project-testinomial {}
}

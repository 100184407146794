.ryaz-shadow {
  position: fixed;
  bottom: 36px;
  left: 50%;
  transform: translate(-50%, -50%);

  &-arrow {
    margin: auto;
    height: 25px;
    width: 25px;
    transform: rotate(45deg);
    filter: drop-shadow(28px 28px 18px rgba(77, 229, 175, 0.6));
  }

  &-ellipse {
    height: 6px;
    width: 60px;
    margin-top: 18px;
    background: rgba(77, 229, 175, 0.3);
    filter: blur(8px);
  }
}

.fancy-label {
  @include flex-center;
  height: fit-content;
  width: fit-content;
  padding: 0 16px;
  border-radius: radius();
  color: #000;
  margin-right: 12px;
  height: 30px;
  font-size: 12px;
  font-family: $primary-font;

  &--purple {
    background: linear-gradient(90deg, rgb(222, 170, 255) 0%, rgb(216, 187, 255) 100%);
  }

  &--teal {
    background: linear-gradient(90deg, rgb(232, 255, 250) 0%, rgb(211, 254, 245) 100%);
  }

  &--orange {
    background: linear-gradient(90deg, rgb(250, 225, 221) 0%, rgb(254, 197, 187) 100%);
  }
}

header {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 0 18px;
  width: 100%;
  height: $nav-height-mobile;
  z-index: 6;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);

  @include over-mobile {
    height: $nav-height-desktop;
    padding: 0 30px;
  }

  .header-logo {
    height: 25px;
    width: 25px;
    position: relative;
    z-index: 10;
  }

  // .header-burger {}
  .header-close {
    width: 56px;
    height: 56px;

    svg {
      height: 16px;
      width: 16px;
    }
  }

  .header {

    &-mobile-view {

      @include over-tablet {
        display: none;
      }
    }

    &-desktop-view {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      justify-content: flex-end;
      align-items: center;
      gap: 56px;
      padding: 0 56px;

      a {
        font-size: 16px;
        line-height: 2;
        color: #FFFFFF;
        text-decoration: none;
        height: 100%;
        display: contents;
      }

      &-link {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 3px solid transparent;

        &.active {
          border-bottom: 3px solid $clr-primary;
        }

        &:hover {
          border-bottom: 3px solid $clr-primary;
        }
      }

      @include over-tablet {
        display: flex;
      }
    }
  }
}


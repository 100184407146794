.nav-icon {
  cursor: pointer;
  height: 21px;
  width: 18px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  border: 0;
  background: none;

  @include over-mobile {
    height: 26px;
    width: 22px;
  }

  &-layer {
    width: 13px;
    height: 3px;
    background: white;
    border-radius: radius();

    @include over-mobile {
      width: 16px;
    }

    &:nth-child(2) {
      width: 18px;

      @include over-mobile {
        width: 22px;
      }
    }
  }
}

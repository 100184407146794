.color-scheme {
  display: flex;
  height: 80px;

  &-block {
    display: flex;
    flex-direction: column; /* Ensures "Copied" appears below */
    align-items: center;
    justify-content: center;
    width: 20%;
    height: 100%;

  }

  &-label {
    display: flex;
    align-items: center;
    column-gap: 3px;
    cursor: pointer;

    @include over-tablet-medium {
      column-gap: 12px;
    }

    svg {
      @include over-tablet-medium {
        height: 24px;
        width: 24px;
      }
    }

    p {
      font-family: $primary-font;
      font-weight: 500;
      font-size: 10px;
      color: #ffffff;

      @include over-tablet-medium {
        font-size: 24px;
        line-height: 34px;
      }
    }
  }
}

.white-back {
  p {
    color: #333;
  }
}

.color-text {
  font-size: 14px;
  font-weight: bold;
}

.copied-message {
  font-size: 14px;
  color: #fff;
  opacity: 0.8;
  margin-top: 4px; /* Space between color text and "Copied" */
  text-align: center;
  transition: opacity 0.3s ease-in-out;
}

.white-back .copied-message {
  color: #333;
}
.card-slider {
  width: 100%;
  height: 500px;

  &-nav {
    position: absolute;
    top: 50%;

    &.prev {
      left: 12px;

      @include over-tablet {
        left: 24px;
      }

      svg {
        transform: rotate(180deg);
      }
    }

    &.next {
      right: 12px;

      @include over-tablet {
        right: 24px;
      }
    }
  }

  .swiper-slide {
    @include flex-center;
    text-align: center;
    font-size: 18px;
    background: #fff;
    width: 250px !important;

    .slide-overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.6);
      transition: 0.3s background ease;
      z-index: 5;
    }

    &-active {
      .slide-overlay {
        background: transparent;
      }
    }
  }
}

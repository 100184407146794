$clr-bg-lighter: #041534;
$clr-bg-darker: #030f26;
$clr-primary: #09b477;
$clr-primary-light: #4de5af;
$clr-primary-dark: #3bb98c;
$bp-tablet: 600px;
$bp-desktop: 900px;

$rgba-light-green: rgba(77, 229, 175, 1);
$grey-border: 1px solid rgba(255, 255, 255, 0.05);
$clr-accent: #ffffff;

.arrow-button {
  @include flex-center;
  cursor: pointer;
  width: 48px;
  height: 48px;
  background: rgba(0, 0, 0, 0.4);
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
  border: 0;
  backdrop-filter: blur(50px);
  border-radius: radius();
  z-index: 10;

  svg {
    height: 24px;
    width: 24px;
  }
}

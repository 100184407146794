.contact-page {
  flex: 1;
  display: flex;
  padding: 24px;

  &-form {
    margin: auto;
    width: 100%;
    max-width: 800px;
  }
}

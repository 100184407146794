.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;

  &-tagline {
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 6px;

    @include over-mobile {
      font-size: 36px;
      line-height: 48px;
    }
  }

  &-info {
    max-width: 500px;
    font-size: 14px;
    line-height: 24px;
    color: #E4E4E4;
    text-align: center;
    margin-bottom: 48px;

    @include over-mobile {
      font-size: 16px;
      line-height: 30px;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;

    div {
      display: flex;
      gap: 24px;
      flex-direction: column;

      @include over-tablet {
        flex-direction: row;
      }
    }

    button {
      margin-left: auto;
    }
  }
}

.ryaz-logo {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: transparent;

  &-left {
    width: 50%;
    height: 100%;
    background: linear-gradient($clr-primary-light, $clr-primary-dark);
    border-radius: radius(0.4) 0 radius(0.4) radius(0.4);
  }

  &-right {
    width: 50%;
    height: 50%;
    background: linear-gradient($clr-primary-light, $clr-primary-dark);
    border-radius: 0 radius(0.4) radius(0.4) 0;
  }
}

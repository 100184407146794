.project-button {
  @include flex-center;
  column-gap: 16px;
  height: 50px;
  background: rgba(0, 0, 0, 0.4);
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(50px);
  border-radius: radius();
  font-size: 16px;
  color: #F4F4F4;
  padding: 0 18px;
  border: 0;
  cursor: pointer;
}
